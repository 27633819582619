import { useTheme } from "../theme"
import { Color } from "../types"

export function useColor(inputColor?: Color) {
  const { color: colorTheme } = useTheme()

  if (typeof inputColor == "object") return inputColor.custom

  let color: string
  switch (inputColor) {
    case "inverted":
      color = colorTheme.text.inverted
      break
    case "link":
      color = colorTheme.secondary2
      break
    case "primary":
      color = colorTheme.primary
      break
    case "success":
      color = colorTheme.success
      break
    case "error":
      color = colorTheme.error
      break
    case "light":
      color = colorTheme.text.light
      break
    case "medium":
      color = colorTheme.text.medium
      break
    case "accent":
      color = colorTheme.accent
      break
    case "disabled":
      color = colorTheme.disabled
      break
    case "base1":
      color = colorTheme.base1
      break
    case "base2":
      color = colorTheme.base2
      break
    case "base3":
      color = colorTheme.base3
      break
    case "base4":
      color = colorTheme.base4
      break
    case "base5":
      color = colorTheme.base5
      break
    case "base6":
      color = colorTheme.base5
      break
    case "background":
      color = colorTheme.background
      break
    case "secondary1":
      color = colorTheme.secondary1
      break
    case "secondary2":
      color = colorTheme.secondary2
      break
    case "secondary3":
      color = colorTheme.secondary3
      break
    case "border":
      color = colorTheme.border
      break
    case "attention":
      color = colorTheme.attention
      break
    case "selection":
      color = colorTheme.selection
      break
    case "default":
    default:
      color = colorTheme.text.default
      break
  }

  return color
}
