import React, { ReactNode } from "react"
import { View } from "react-native"

import { Column } from "../../layout/FlexBox"
import { Label } from "../../typography/Label"

export type ErrorFieldProps = {
  children: ReactNode
  errorMessage?: string
}

const FONT_SIZE = 12

// An element paired with an optional error message bellow.
export function ErrorField({ children, errorMessage }: ErrorFieldProps) {
  // In case an error message is present - display a styled label.
  const errorLabel = errorMessage ? (
    <Label color="error" text={errorMessage} fontSize={FONT_SIZE} />
  ) : undefined

  // Make sure that the view beneath the received children node
  // has always the same size in order to prevent
  // a nasty element shifting effect.
  const errorView = <View style={{ height: FONT_SIZE }}>{errorLabel}</View>

  return (
    <Column gap="XXXXS">
      {children}
      {errorView}
    </Column>
  )
}
